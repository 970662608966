<template>
  <v-container class="py-7">
    <div class="heading mb-2">{{ userName || "Профиль" }}</div>
    <div class="mb-3">{{ userNumber || "Номер телефона" }}</div>

    <v-sheet
      v-for="(item, i) in list"
      :key="i"
      class="custom-field"
      v-ripple="{ class: 'secondary--text' }"
      @click="$router.push(item.to)"
    >
      <v-icon color="primary" size="32" class="mr-4">{{ item.icon }}</v-icon>
      <span>{{ item.name }}</span>
      <v-icon color="secondary" size="32" class="ml-auto mr-n2">{{
        icons.mdiChevronRight
      }}</v-icon>
    </v-sheet>
  </v-container>
</template>

<script>
import {
  mdiChevronRight,
  mdiMapMarkerMultiple,
  mdiClipboardText,
  mdiAlertDecagram,
  mdiCog,
} from "@mdi/js";

export default {
  data: () => ({
    icons: {
      mdiChevronRight,
    },
  }),

  computed: {
    profile() {
      return this.$store.state.user.profile;
    },

    userName() {
      return this.profile?.full_name;
    },

    userNumber() {
      return this.profile?.contact_phone;
    },    

    isLoyaltyEnabled() {
      return this.$store.getters["app/canUseBonuses"];
    },

    list() {
      return [
        {
          name: "Ваши адреса",
          icon: mdiMapMarkerMultiple,
          to: {
            name: "Addresses",
          },
        },
        {
          name: "История заказов",
          icon: mdiClipboardText,
          to: {
            name: "History",
          },
        },
        this.isLoyaltyEnabled && {
          name: "Ваши баллы",
          icon: mdiAlertDecagram,
          to: {
            name: "Bonuses",
          },
        },
        {
          name: "Настройки профиля",
          icon: mdiCog,
          to: {
            name: "UserSettings",
          },
        },
      ].filter(Boolean);
    },
  },
};
</script>
